
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap'); */

/* @font-face {
  font-family: 'Poppins', sans-serif;
  src: url('./assets/css/Poppins/Poppins-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('./assets/css/Poppins/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('./assets/css/Poppins/Poppins-Light.ttf');
  font-weight: 300;
} */

@font-face {
  font-family: 'Microsoft JhengHei';
  src: url('./assets/css/fonts/microsoft_jhenghei.woff') format('woff');
}
body {
  margin: 0;
  font-family: 'Microsoft JhengHei' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: 'Microsoft JhengHei' !important;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.main-content-wrapper {
  /* width: calc(100% - var(--DrawerWidth)); */
  min-height: 100vh;
  padding: 0;
  float: right;
  transition: 0.5s all;
}
@media only screen and (min-width: 991px) {
  .main-content-wrapper {
    width: calc(100% - var(--DrawerWidth));
  }
}
@media only screen and (max-width: 991px) {
  .main-content-wrapper {
    width: calc(100% - 74px);
  }
}
@media only screen and (max-width: 660px) {
  .main-content-wrapper {
    width: calc(100% - 0px);
  }
}
