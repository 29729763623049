.public-DraftStyleDefault-block {
    margin: 1em 0 0 !important;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
    margin: 16px 0 0 !important;

    .public-DraftStyleDefault-orderedListItem,
    .public-DraftStyleDefault-unorderedListItem {
        &:first-child {
            .public-DraftStyleDefault-block {
                margin-top: 0 !important;
            }
        }

    }
}

.rdw-editor-main [data-text="true"],
.readonly-editor [data-text="true"] {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
    background-color: inherit;
    text-align: inherit;
    text-decoration: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    word-spacing: inherit;
    text-indent: inherit;
    white-space: inherit;
    text-shadow: inherit;
    line-height: inherit;
}
.rdw-editor-toolbar {
    visibility: visible !important;
    border: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
}

.rdw-editor-main {
    border: none;
    height: 100%;
    padding: 0 10px;
    border-radius: 4px;
    min-height: 200px;
    // background: var(--greyBGE6)
}