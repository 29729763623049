.panel-tabel-sec {
    background: transparent;
    border-radius: 8px;
    box-shadow: none !important;

    .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        border: 1px solid var(--greyBG);
        border-radius: 8px 8px 0px 0px;
        border-bottom: none;

        h1 {
            font-size: 16px;
            font-family: var(--Roboto);
            font-weight: 500;
            margin: 0;
        }

        button {
            padding: 0;
            margin: 0;
            border: none;
            background: transparent;
            line-height: 0;

            svg {
                width: 20px;
                height: 5px;
            }
        }
    }

    .MuiDataGrid-columnHeaders {
        margin-top: 0;
    }

    .MuiDataGrid-root.MuiDataGrid-withBorderColor {
        border-color: transparent;
        // border-radius: 8px
        gap: 15px;
    }

    .table-header {
        &+.MuiBox-root {
            .MuiDataGrid-root.MuiDataGrid-withBorderColor {
                border-radius: 0px 0px 8px 8px;
            }
        }
    }

    .app-grid-tool-bar {
        // flex-direction: row-reverse;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 10px 20px 10px 10px;
        border-bottom: 1px solid transparent;
        background: #fff;
        padding-left: 24px;
        padding-right: 24px;
        height: 96px;
        

        .MuiFormControl-root.MuiTextField-root {
            .MuiInputBase-root {
                border-radius: 10px;
                overflow: hidden;
                border: 1px solid #dce0e4;
                flex-direction: row-reverse;

                &.MuiInput-underline:before,
                &::after {
                    display: none !important;
                }

                &>svg {
                    order: 3;
                    width: 40px;
                    height: 40px;
                    padding: 8px;
                    background: var(--Primary);
                    color: var(--White);
                }

                & input.MuiInputBase-input {
                    order: 1;
                    padding: 16.5px 14px;
                    height: 1.4375em;
                    font-size: 1rem;
                }

                & .MuiButtonBase-root {
                    order: 2;
                }
            }
        }

        .MuiFormControl-root {
            max-width: 240px;
            margin-top: 0;
            padding-bottom: 0;
            width: 100%;
        }

        .MuiButtonBase-root.MuiButton-root {
            color: var(--Black00);
            display: none;
        }
    }
    .MuiDataGrid-columnHeaders{
        margin-bottom: 10px;
    }
    .MuiDataGrid-columnHeaders,
    .MuiDataGrid-footerContainer {
        background-color: #000000;
        border-radius: 0px;
        margin-top: 0px;
        height: 50px !important;
        min-height: 50px !important;

        .MuiDataGrid-columnHeaderTitle {
            font-size: 14px;
            color: #FFFFFF;
            font-weight: 400;
        }

        .MuiCheckbox-indeterminate,
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            color: var(--White);
        }
    }
    .MuiDataGrid-virtualScrollerRenderZone{
        // gap: 10px
    }

    .MuiDataGrid-virtualScroller {
        .MuiDataGrid-row {
            border-radius: 0px;
            background: #fff;
            // padding-top: 6px;
            // padding-bottom: 6px;

            // padding-left: 10px;
            // padding-right: 10px;
            &.Mui-selected {
                position: relative;
                background-color: transparent;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: var(--adminBG);
                    opacity: 0.2;
                    border-radius: 7px;
                }
            }
        }

        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            color: var(--Black00);
        }
    }

    .MuiDataGrid-footerContainer {
        background: #fff;
        border-color: transparent;
        border-radius: 0 0 10px 10px;
    }


    .PC-Table {
        @media only screen and (max-width: 660px) {
            & {
                height: 0 !important;
                min-height: 0 !important;
                max-height: 0 !important;
                overflow: hidden;
            }
        }

        @media only screen and (min-width: 661px) {
            & {
                display: flex;
            }
        }

        .main-loader {
            height: calc(100vh - 225px);
        }
    }

    .mobile-Table {
        padding: 15px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        @media only screen and (min-width: 661px) {
            & {
                display: none;
            }
        }
    }

    .mob-table-wrp {
        &.f-wrp {
            display: none;
        }

        .question,
        .questions {
            .readonly-editor {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100vw - 150px);
            }
        }

        .mob-table-search {
            margin-bottom: 20px;
            position: relative;

            .mob-table-input {
                border-radius: 13px;
                border: 1px solid var(--greyBG);
                background-color: var(--White);
                width: 100%;
                height: 45px;
                padding: 7px 15px;
                font-size: 14px;

                &:focus-visible {
                    outline: -webkit-focus-ring-color auto 0px;
                }
            }

            &::after {
                content: '';
                display: block;
                width: 50px;
                height: 45px;
                background-image: url('../images/icons/searchIcon.svg');
                background-color: var(--Secondary);
                position: absolute;
                right: 0;
                top: 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 24px;
                border-radius: 0px 13px 13px 0;
            }
        }

        .empty-placeholder.f-wrp {
            height: calc(100vh - 225px);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            .placeholder-con {
                img {
                    width: 100%;
                    max-width: 250px;
                    margin: 0 auto;
                }

                h4 {
                    margin-bottom: 0;
                    font-weight: 700;
                    color: #FF7940;
                }

                p {
                    color: #7C8B9D;
                    margin-bottom: 0;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }

        @media only screen and (max-width: 660px) {
            &.f-wrp {
                display: block;
            }
        }

        &>ul {
            border: 1px solid var(--greyBG);
            border-radius: 10px;

            &>li {
                .flex-main-wrp {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 100%;
                }

                &.option-selected {
                    background-color: var(--qnActive);
                }

                &:not(:last-child) {
                    border-bottom: 1px solid var(--greyBG)
                }

                .each-sec-box {
                    padding: 10px;
                    width: 100%;

                    h4 {
                        margin: 0;
                        font-weight: 700;
                        font-size: 15px;
                    }

                    span {
                        font-size: 13px;
                    }

                    p {
                        margin: 0;
                        font-size: 13px;
                    }

                    .flex-wrp {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        span {
                            color: #4C483E;
                            font-weight: 400;
                        }
                    }

                    .auto-flex {
                        flex-wrap: wrap;
                    }

                    .flex-gap5 {
                        gap: 2px;

                        &.flex-wrp.auto-flex {
                            flex-direction: column;
                            align-items: flex-start;
                            width: 100%;

                            p {
                                color: #4C483E;
                            }

                            &+.flex-wrp.auto-flex {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                        }
                    }

                    .status {
                        height: 25px;
                        font-size: 12px;
                        width: max-content;
                    }

                    &:nth-child(1) {
                        width: max-content;
                    }

                    &:nth-child(3) {
                        width: max-content;
                    }

                    .btn-wrp {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        position: relative;
                        z-index: 1;

                        button {
                            padding: 0;
                            background: transparent;
                            border: none;
                            min-width: 2px;
                            cursor: pointer;

                            &.formIcon {
                                svg {
                                    path {
                                        fill: #FF7940;
                                    }
                                }
                            }
                        }
                    }

                    .question {
                        max-width: 100%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        padding-bottom: 5px;
                    }

                    .rich-text-msg {
                        z-index: 0;
                        overflow: hidden;
                        max-height: 40px;

                        .public-DraftStyleDefault-block {
                            margin: 0;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }

                    .text-overflow-elips {
                        span {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 100%;
                        }
                    }

                    .subjectList {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;

                        i {
                            font-style: normal;
                        }
                    }

                    .status-with-btn {
                        position: relative;
                        z-index: 2;

                        button.MuiIconButton-root {
                            position: absolute;
                            left: 0;
                            top: 0;
                            padding: 0;
                        }

                        &.relative-btn {
                            button.MuiIconButton-root {
                                position: relative;
                                padding: 5px;
                            }
                        }

                    }

                    .profile-img-sec {
                        span {
                            width: 50px;
                            height: 50px;
                            display: block;
                            border-radius: 100%;
                        }
                    }

                    .time-pill {
                        max-width: max-content;
                        padding: 2px 7px;
                        font-size: 11px !important;
                        color: var(--White) !important;
                        background: var(--Accent);
                        border-radius: 35px;
                        margin-left: 5px;
                    }
                }

                .full-li-btn {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    background: transparent;
                    border: none;
                    cursor: pointer;
                }
            }
        }

        .completed,
        .active {
            min-width: 80px;
            // height: 30px;
            border-radius: 6px;
            display: inline-block;
            text-align: center;
            line-height: 1.8;

            svg {
                width: 17px;
                height: 17px;
                margin-right: 2px;
            }
        }

        .completed {
            color: #FFBA0A !important;
            background: #FFF7E2 !important;
        }

        .active {
            background: #EDF7ED !important;
            color: #1FAF38 !important;
        }



        &.childView {
            ul {
                li {
                    .each-sec-box {
                        &:nth-child(1) {
                            border-right: 1px solid var(--greyBG);
                            padding-top: 10px;
                            padding-bottom: 5px;
                            text-align: center;

                            h4 {
                                color: #0F0A01;

                                &:nth-child(1) {
                                    font-weight: 900;
                                }

                                &:nth-child(2) {
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.prevTest {
            .status-with-btn {
                .score+button.MuiIconButton-root {
                    width: 100%;
                    height: 100%;

                    svg {
                        height: 30px;
                        width: 30px;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }
}

.image-uploader{
    display: flex;
    gap: 30px;
    .img-upload-wrp.f-wrp{
        width: auto;
    }
}

.uploaded-img{
    position: relative;
    display: inline-block;
    .MuiButtonBase-root{
        position: absolute;
        right: 0px;
        top: 0px;
    }   
    img{
        width: 100% !important;
        height: 120px !important;
        object-fit: contain;
        object-position: center;
    }
}
.MuiFormControlLabel-root{
    .MuiCheckbox-root{
        color: #134870;
    }
}
