.tab-sec-wrp {
    .tab-header {
        min-height: 35px;
        padding-left: 15px;

        .MuiTabs-flexContainer {
            gap: 15px;
            border-bottom: 1px solid var(--greyBG);
            .user-main-wrp & {
                border-bottom: 0px solid var(--greyBG)
            }
        }

        button {
            font-size: 14px;
            border-radius: 7px 7px 0px 0px;
            min-width: 150px;
            font-weight: 400;

            b {
                font-weight: 400;
            }

            @media only screen and (max-width: 991px) {
                border-radius: 0;
                min-width: auto;
                font-size: 16px;
            }

            &.Mui-selected {
                font-weight: 700;
            }

            &.tab-items {
                padding: 20px;
                padding-bottom: 10px;
                padding-top: 10px;
                flex-direction: row;
                min-height: 60px;
                align-items: center;
                min-width: 20px;
                gap: 5px;
                line-height: 0;
                background: #fff;
                border-top: 7px solid transparent;
                font-size: 16px;
                padding-right: 50px;
                min-width: 180px;
                justify-content: flex-start;
                b{
                    font-weight: 700;
                }
                &.Mui-selected{
                    border-top-color: #134870;
                }
            }

            svg {
                width: 18px;
                height: 30px;
                margin-bottom: 0;
            }
        }

        .MuiTabs-indicator {
            background: transparent;
        }

        .MuiTabs-scroller {
            &::-webkit-scrollbar {
                width: 0px;
                height: 0;
            }
        }

        @media only screen and (max-width: 991px) {
            .MuiTabs-scroller {
                overflow-x: scroll !important;
                // width: calc(100% - 25px);
                // min-width: calc(100% - 25px);
                // max-width: calc(100% - 25px);
                border-bottom: 1px solid;
            }
        }
    }

    .tab-body {
        border-radius: 0px 10px 10px 10px;
        border: 1px solid transparent;

        .MuiTabPanel-root {
            padding: 0;
        }

        .panel-tabel-sec {
            padding-bottom: 0;
        }

        @media only screen and (max-width: 991px) {
            border-radius: 0 !important;
        }
    }
}