.main-content-wrapper {
  background: var(--adminBG);

  .panel-main-header {
    &+.main-container-wrp {
      padding: 0px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }


  .panel-main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    // border-bottom: 1px solid var(--greyBG);
    background: var(--Secondary);
    color: var(--White);
    z-index: 9;

    @media only screen and (max-width: 660px) {
      & {
        padding-left: 50px;
      }
    }

    h1 {
      margin: 0;
      font-size: 1.3rem;
      font-weight: 600;
    }

    .header-basic-info {
      display: flex;
      align-items: center;

      span {
        display: block;
        width: 35px;
        height: 35px;

        img {
          object-fit: cover;
          object-position: center;
          border-radius: 100%;
          width: 100%;
          height: 100%;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .language-box {
        display: none;

        span {
          background-color: var(--greyBGE6);
          padding: 8px;
          line-height: 1;
          border-radius: 5px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .header-basic-con {
        padding-left: 15px;
        color: inherit;

        @media only screen and (max-width: 660px) {
          & {
            display: none;
          }
        }

        h4 {
          margin: 0;
          color: inherit;
          font-weight: 500;
          font-size: 14px;
        }

        p {
          margin: 0;
          color: inherit;
          font-size: 12px;
        }
      }
    }
  }

}