:root {
 
  // color

  --Accent: #1D1D1B;
  --Secondary: #1D1D1B;
  --Secondary62: #1D1D1B;
  --Secondary77: #1D1D1B;
  --White: #FFF;
  --Background: #EDF6FF;
  --placeholder: #7B7B7B;
  --Text: #303030;
  --Black: #09101D;
  --Black00: #000000;
  --Gold: #716128;
  --Error: #FF4842;
  --Yellow: #EFDE9F;
  --darkShade: rgba(0, 0, 0, 0);
  --darkShade16: rgba(0, 0, 0, 0.16);
  --darkShade25: rgba(0, 0, 0, 0.25);
  --darkShade5: rgba(0, 0, 0, 0.5);
  --whiteShade01: rgba(255, 255, 255, 0.1);
  --whiteShade25: rgba(255, 255, 255, 0.25);
  --whiteShade05: rgba(255, 255, 255, 0.5);
  --whiteShade06: rgba(255, 255, 255, 0.60);
  --whiteShade08: rgba(255, 255, 255, 0.8);
  --blueShade24: rgba(145, 158, 171, 0.24);
  --blueShade08: rgba(145, 158, 171, 0.8);
  --blueShade04: rgba(0, 117, 255, 0.4);
  --blueShade02: rgba(9, 16, 29, 0.2);
  --blueShade06: rgba(0, 113, 188, 0.6);
  --shade24: rgba(21, 41, 76, 0.24);
  --shade25: rgba(209, 217, 226, 0.25);
  --shade06: rgba(21, 41, 76, 0.6);
  --shade0: rgba(21, 41, 76, 0);
  --yellowShade0: rgba(255, 236, 166, 0);

  // Admin color

  --adminBG: #F9FBFD;
  --LogoGrey: #5F6D7E;
  --navPlaceHolder: #7C8B9D;
  --greyBG: #D1D9E2;
  // --greyBGE6: #E6E9EC;
  --blueBgLyt: #437EF7;
  --shade004: rgba(16, 24, 40, 0.04);

}
.drawer-open{
  --DrawerWidth: 240px;
}
.drawer-close{
  --DrawerWidth: 74px;
}

@media only screen and (max-width: 661px) {
  .drawer-open{
    --DrawerWidth: calc(100vw - 30px);
  }
  .drawer-close{
    --DrawerWidth: 0px;
  }
}