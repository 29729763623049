html,
body {
    /* background: #fff; */
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto !important;
    font-family: 'Microsoft JhengHei' !important;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.f-wrp {
    width: 100%;
    display: block;
    position: relative;
    float: left;
}

img {
    width: 100%;
    display: block;
}

ul,
li {
    list-style: none;
    padding-left: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.welcome-container {
    background-image: url('./../images/welcomeBG.png');
}
.welcome-container .MuiContainer-root {
    background: transparent !important;
}
.welcome-bnner {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    width: auto;
    justify-items: end;
    margin: 0 auto;
}

.welcome-bnner .contnt-wrp.f-wrp {
    width: auto;
}

.welcome-container * {
    color: #fff;
}

.welcome-bnner .contnt-wrp.f-wrp h4 {
    margin: 0;
}

.welcome-bnner .contnt-wrp.f-wrp h1 {
    text-align: center;
    margin: 0;
    font-family: 'Microsoft JhengHei';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(26, 20, 64, 0.25);
    letter-spacing: 2px;
}

.welcome-bnner .contnt-wrp.f-wrp h1 span {
    font-weight: 400;
}

.custom-navbar{
    background: #1D1D1B !important;
    padding-top: 5px;
    padding-bottom: 5px;
}
.login-img-slogan {
    display: block;
    width: 100px;
    margin: 0 auto;
}
.login-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: url('./../images/banner-bg.png');
    margin: 0 auto;
    font-size: 22px;
    color: #fff;
    text-align: center;
    align-items: center;
    border-radius: 0 0 15px 15px;
    display: flex;
    justify-content: center;
    min-height: 125px;
    overflow: hidden;
    padding: 15px 15px 25px;
    max-width: 395px;
}

.login-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    left: 0;
    top: 0;
}
.login-bg-sec{

}
.login-form {
    padding-top: 20px;
}

label.MuiFormLabel-root {
    color: #000;
    left: 16px;
}

label.MuiFormLabel-root.Mui-focused {
    color: #000;
}

.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 5px;
}

.note-sec .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 10px;
}

.note-sec {
    padding-top: 30px;
}

.note-sec .MuiInputBase-input.MuiOutlinedInput-input {
    padding-left: 0px;
    padding-right: 0px;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #C6C6C6 !important;
    padding: 0 25px !important;
}

.MuiInputBase-input.MuiOutlinedInput-input {
    padding-left: 30px;
    padding-right: 30px;
}

.MuiTextField-root .MuiInputBase-root .MuiInputAdornment-root .MuiTypography-root{
    font-size: 14px;
}
.MuiTextField-root .MuiInputBase-root .MuiInputAdornment-root + .MuiInputBase-input{
    padding-left: 14px;
}

.MuiButtonBase-root.MuiButton-root {
    background: #1D1D1B;
    color: #fff;
}

.each-Nav-Item {
    padding-left: 15px;
    border: 1px solid #fff;
    margin-bottom: 15px;
    border-radius: 6px;
    color: #fff !important;
}

.each-Nav-Item:hover,
.each-Nav-Item.active {
    color: #FFA63D !important;
    background: #fff !important;
}

.each-Nav-Item:hover svg path,
.each-Nav-Item.active svg path {
    fill: #FFA63D;
}


.table-request{
    margin-bottom: 70px;
    background: transparent;
}
.table-head-bg{
    background-color: #fff;
}
.table-request *{
    color: #1D1D1B;
}
.table-filter-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
}
.table-request thead th{
    color: #fff;
}
/* 
.table-request table.MuiTable-root thead tr th:nth-child(1),
.table-request table.MuiTable-root tbody tr td:nth-child(1) {
    display: none;
} */

.table-request table.MuiTable-root thead tr th,
.table-request table.MuiTable-root tbody tr td,
.table-request table.MuiTable-root tbody tr th {
    text-align: center;
    /* border-bottom-color: rgba(242, 203, 4, 0.83); */
}
.table-request table.MuiTable-root thead tr th{
    background-color: #000;
}
.table-request table.MuiTable-root thead tr th *{
    color: #fff;
}
.table-request table.MuiTable-root thead tr th span {
    font-weight: 500;
    color: #fff;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(234, 218, 139, 0.28);
}
.listing-table{
    border-collapse: separate;
    -webkit-border-vertical-spacing: 10px;
    background: #f1f1f1;
}
.listing-table .MuiAvatar-circular {
    border-radius: 5px;
}
.MuiContainer-root{
    background: #f1f1f1;
}
.table-request table.MuiTable-root tbody tr td,
.table-request table.MuiTable-root tbody tr th{
    background-color: #fff;
}

/* .table-request table.MuiTable-root tbody tr td:nth-child(5),
.table-request table.MuiTable-root tbody tr td:nth-child(6) {
    color: rgba(3, 89, 140, 1);
    text-decoration: underline;
} */

.new-request-modal .new-request-wrpp {
    background: #fff;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 35px 45px;
    overflow: auto;
    max-width: 900px;
    margin: 0 auto;
    float: none;
}

.new-request-modal .close-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    background: #fff;
    color: #333;
}

.new-request-wrpp * {
    font-size: 14px;
}

.address-wrp ul li .icon {
    width: 15px;
    display: inline-block;
}

.address-wrp ul li {
    font-size: 14px;
    color: rgba(3, 89, 140, 1);
    text-decoration: underline;
    margin-top: 5px;
    padding-left: 35px;
    position: relative;

}

.address-wrp ul li.title {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    text-decoration: none;
    margin-bottom: 15px;
    padding-left: 0;
}

.address-wrp ul li.title span {
    position: relative;
}

.address-wrp ul li span {
    margin-right: 15px;
    position: absolute;
    left: 0;
}

.address-wrp ul li b {
    color: #000;
    text-decoration: none;
}

.address-wrp ul li.address {
    text-decoration: none;
}

.address-wrp ul li.address p {
    text-decoration: underline;
    display: block;
    margin: 0;
}

.about-project-wrp.f-wrp {
    padding: 30px 0 15px;
}

.abt-title {
    display: block;
    margin-bottom: 20px;
    font-weight: 600;
    font-family: 'Microsoft JhengHei' !important;
}

.initial-info {
    display: inline-block;
    border: 1px solid rgba(255, 166, 61, 1);
    padding: 17px 25px;
    border-radius: 10px;
}

.initial-info span {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.initial-info span img {
    margin-right: 10px;
    width: 20px;
}

.info-table.f-wrp table {
    width: 100%;
}

.info-table.f-wrp table tr td {
    background: rgba(252, 252, 252, 1);
}

.info-table.f-wrp table tr:nth-child(odd) td:nth-child(odd),
.info-table.f-wrp table tr:nth-child(even) td:nth-child(even) {
    background: rgba(247, 247, 247, 1);
}


.info-table.f-wrp table {
    margin: 50px 0 10px;
}

.info-table.f-wrp table td {
    padding: 15px 25px;
    width: 50%;
}

.info-table.f-wrp table td b {
    padding-left: 25px;
}

.roof-detail-table table {
    width: 100%;
    border: 1px solid rgba(230, 143, 19, 1);
    border-radius: 10px;
    padding: 20px 10px;
}

.roof-detail-table table * {
    vertical-align: middle;
    text-align: center;
}

.roof-detail-table table td img.icon {
    display: inline-block;
    width: 50px;
}

.roof-detail-table table td span {
    padding-right: 25px;
}

.roof-detail-table.f-wrp {
    margin-top: 50px;
    margin-bottom: 30px;
}

.roof-detail-table span.roof-detail-title {
    position: absolute;
    left: 50px;
    top: -10px;
    background: #fff;
    padding: 0px 15px;
    font-weight: 500;
}

.snapshot-table table {
    width: 100%;
}

.snapshot-table table td {
    width: 50%;
}

.snapshot-table table tr td {
    width: calc(50% - 15px);
    padding-bottom: 30px;
}

.snapshot-table table tr td:nth-child(odd) {
    float: left;
}

.snapshot-table table tr td:nth-child(even) {
    float: right;
}

.each-blk-snapshot.f-wrp {
    display: flex;
    border: 1px solid #D8D8D8;
    border-radius: 7px;
    padding: 25px 35px;
    align-items: center;
}

.each-blk-snapshot .img-sec {
    width: 35px;
    margin-right: 20px;
    height: 35px;
    min-width: 35px;
}

.each-blk-snapshot .img-sec img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.summery-table.f-wrp table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #F2CB04;
    padding: 25px 20px;
    border-radius: 7px;
}

.summery-table.f-wrp table tr td:first-child {
    text-align: left;
    color: #4B4B4B;
}

.summery-table.f-wrp table tr td {
    text-align: center;
    width: 25%;
    padding-top: 15px;
}

.summery-table.f-wrp table tr td:last-child {
    text-align: right;
}

.summery-table.f-wrp table.total-summery {
    margin-top: 30px;
    padding: 20px 20px;
    background: #F2CB04;
}

.summery-table.f-wrp table.total-summery td {
    color: #fff;
    padding-top: 0;

}

.summery-table.f-wrp table.total-summery td span,
.summery-table.f-wrp table.total-summery td b {
    font-size: 18px;
    color: #fff;
}

.abt-title .status {
    background: #67D113;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    padding: 3px 9px;
    display: inline-block;
    border-radius: 5px;
    margin-left: 10px;
}

/* .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    background: linear-gradient(90deg, #E68F13 0%, #EBAF13 100%);
} */

.calendar-wrapp .calendar table.month th.day-header {
    visibility: hidden;
}

.calendar-wrapp .calendar table.month th.day-header::first-letter {
    visibility: visible;
}

.calendar-wrapp .calendar table.month th.day-header {
    color: #111111;
    font-style: normal;
    font-weight: 500;
    position: relative;
    left: 3px;
    padding-bottom: 15px;
}

.calendar-wrapp .calendar table.month td.day .day-content {
    color: #838383;
    width: 32px;
    border-radius: 100%;
    margin: 0 auto;
}

.calendar-wrapp .calendar table.month th.month-title,
.calendar-wrapp .calendar table.month th.day-header,
.calendar-wrapp .calendar table.month td.day .day-content {
    font-size: 14px;
}

.calendar-wrapp .calendar table.month th.month-title {
    font-weight: 700;
    padding-bottom: 15px;
}

.calendar-wrapp .calendar {
    overflow: visible;
}

.calendar .calendar-header {
    display: none;
}


.calendar .months-container .month-container {
    /* max-width: 33.33% !important;
    width: 33.33% !important; */
    height: auto !important;
    margin-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
}

.calendar .months-container .month-container {
    width: 33.33% !important;
}

.calendar .months-container .month-container.month-3 {
    width: 33.33%;
}

.calendar .months-container {
    display: flex !important;
    flex-wrap: wrap !important;
}

.calendar .months-container table.month {
    width: 100%;
}

.calendar-wrapp .calendar table.month td.day .day-content:hover {
    background: #F2CB04;
    cursor: pointer;
    color: #fff;
}

.tabstatus {
    height: 24px;
    min-width: 100%;
    line-height: 0;
    border-radius: 6px;
    cursor: default;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-transform: capitalize;
    padding: 0px 8px;
    font-size: 0.75rem;
    font-family: 'Microsoft JhengHei' !important;
    font-weight: 700;
    color: #212B36;
    position: relative;
    z-index: 9;
}
.tabstatus img{
    height: 100%;
    object-fit: contain;
}

.Out {
    background-color: #DDDDDD;
}

.HighProbability {
    background-color: #f3bb1b;
}

.Visited {
    background-color: #66BF3C;
}

.Requested {
    background-color: #ffff99;
}

.status-select .MuiOutlinedInput-notchedOutline {
    display: none;
}


.status-select label.MuiInputLabel-formControl.MuiInputLabel-animated {
    font-size: 14px !important;
    width: calc(100% - 25px);
    transform: translate(0px, 2px) scale(1);
}

.status-select label.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink,
.status-select label.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused {
    font-size: 14px !important;
    transform: translate(0px, 2px) scale(1);
}

#status-select {
    padding: 0;
}
.propertyImg{
    width: 70px;
    height: 50px;
    border-radius: 0;
}

.list-main-wrp{
    padding-top: 85px;
    padding-bottom: 55px;
}
.breadcrumb a{
    display: flex;
    font-size: 12px;
    color: #134870;
}
.breadcrumb a img{
    width: 20px;
    height: 20px;
}
.title-head{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 25px;
}
.blu-btn{
    background: #134870 !important;
}
.upload-img.single-upload-btn{
    position: relative;
}
.upload-img img{
    width: 55px;
}
.upload-img.single-upload-btn img{
    width: 155px;
    height: auto;
}
.sm-loader{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
}
.sm-loader img{
    width: 55px !important;
    margin: 0 auto;
}
.delete-btn{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    background: transparent;
    padding: 0;
    border-radius: 100%;
    border: none;
}
.button-sec.f-wrp {
    display: flex;
    justify-content: flex-end;
}

.button-sec .cancel {
    background: transparent;
    color: #134870;
    border: 1px solid #134870;
    min-width: 150px;
    border-radius: 4px;
}

.button-sec .fill-btn {
    min-width: 150px;
    background: #134870;
    border-radius: 4px;
}

.img-upload-wrp.f-wrp {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}
.img-upload-wrp.f-wrp .each-img-blk img{
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}
.singleImageUpload label svg{
    width: 155px;
    height: auto;
}
.singleImageUpload .sm-loader svg{
    width: 50px;
    height: auto;
}
.img-upload-wrp .each-img-blk{
    position: relative;
}
.img-upload-wrp .each-img-blk button{
    position: absolute;
    right: 3px;
    top: 3px;
    width: 30px;
    height: 30px;
}
.img-upload-wrp .each-img-blk button svg{
    width: 100%;
    height: 100%;
}
.img-upload-wrp .each-img-blk .profile-img-sec{
    width: 155px;
    height: auto;
}
.img-upload-wrp .each-img-blk .profile-img-sec .rc{
    display: block !important;
    width: 100%;
}

.upload-img .property-image{
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
}
.upload-img img{
    width: 100px;
    height: 100px;
    cursor: pointer;
}
.info-sec-wrp.f-wrp {
    padding: 50px 0;
}
.edit-btn {
    border-radius: 100%;
    border: none !important;
    padding: 0 !important;
    background: transparent !important;
    width: 25px !important;
    height: 25px !important;
    min-width: 25px;
    float: right;
}
.edit-btn img{
    width: 100%;
    height: 100%;
}

.general-info-sec.f-wrp {
    padding: 40px;
    background: #fff;
    border: 0.5px solid #C6C6C6;
    border-radius: 10px;
    margin-bottom: 25px;
}
.info-head.f-wrp span {
    color: #134870;
    font-weight: 700;
}

.general-info-sec label.MuiFormLabel-root{
    font-weight: 700;
}
.general-info-sec label.MuiFormLabel-root.selectLabel.MuiInputLabel-shrink{
    font-weight: 700 !important;
}
 


.general-info-sec textarea.MuiInputBase-input.MuiOutlinedInput-input{
    padding-left: 0;
    padding-right: 0;
}

/* Rich text styles */
.DraftEditor-root {
    border: 1px solid #C6C6C6;
    border-radius: 5px;
    padding: 16.5px 14px;
    height: 200px;
    overflow: scroll;
    overflow-x: hidden;
}
strong {
    font-weight: bold;
}

.delete-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
    box-shadow: none;
    max-width: 550px;
    padding: 15px 45px 25px;
}

.delete-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded p{
    white-space: pre-wrap;
}
.delete-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded h2,
.delete-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiDialogContent-root{
    padding-left: 0;
    padding-right: 0;
}

.delete-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiDialogActions-root.MuiDialogActions-spacing{
    justify-content: space-between;
}

.delete-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiDialogActions-root.MuiDialogActions-spacing button{
    width: calc(50% - 20px);
    padding: 10px;
    border-radius: 3px;
    border: 1px solid;
    background-color: transparent;
}
.delete-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiDialogActions-root.MuiDialogActions-spacing button:nth-child(1){
    color: #000;
    border-color: #000;
}

.delete-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiDialogActions-root.MuiDialogActions-spacing button:nth-child(2){
    background-color: #B3261E;
    border-color: #B3261E;
}












































































































































@media only screen and (max-width: 800px) {

    .each-blk-snapshot.f-wrp,
    .new-request-modal .new-request-wrpp {
        padding: 15px 15px;
    }

    .info-table.f-wrp table td {
        white-space: nowrap;
    }

    .info-table.f-wrp {
        overflow-x: scroll;
    }

    .new-request-modal .close-btn {
        top: 15px;
        right: 15px;
        min-width: auto;
    }

    .new-request-modal .close-btn svg {
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .calendar .months-container .month-container {
        width: 50% !important;
    }
}

@media only screen and (max-width: 650px) {
    .snapshot-table table tr td {
        width: calc(100% - 0px);
    }

    .roof-detail-table table td span {
        /* float: left;
        width: 100%; */
        display: block;
        padding: 0;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 500px) {
    .calendar .months-container .month-container {
        width: 100% !important;
    }
}

@media only screen and (max-width: 450px) {
    .roof-detail-table table td {
        float: left;
        width: 100%;
        padding: 15px;
    }

    .roof-detail-table table td:not(:last-child) {
        border-bottom: 1px solid #e4e4e4;

    }
}

@media only screen and (min-width: 600px){
    .custom-header{
        min-height: 45px;
    }
}